import { initProductFilter } from "../product/product-filter";
import { handleCartModalOpen, handleLoginModalFormSuccess } from "../modals/modals";

const renderedElements = new Set();

export const initReCaptcha = (selector = null) => {
    // map of <htmlID>: <widget/client ID>
    const clientIDs = {};

    // Register reCAPTCHAs
    window['g_onRecaptchaLoad'] = function () {
        document.querySelectorAll('.g-recaptcha').forEach((element) => {
            if (!renderedElements.has(element.id)) {
                clientIDs[element.id] = grecaptcha.render(
                  element,
                  {
                      size: 'invisible',
                      badge: 'bottomright',
                  },
                  true
                );
                renderedElements.add(element.id); // Add the rendered element to the set
            }
        });
    };

    const formSelector = selector ? selector : 'form';

    // Register form submit event listeners
    document.addEventListener('DOMContentLoaded', () => {
        addEventListeners(formSelector);

        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            addEventListeners('#' + event.snippet.id + ' ' + formSelector);

            const snippetActions = {
                'snippet--productList': initProductFilter,
                // v případě úspěšného loginu, provedeme přesměrování
                'snippet-userSignInFormHeader-loginForm': handleLoginModalFormSuccess,
                // v případě snippetu přidání do košíku, otevřeme modal
                'snippet--addToCartModal': handleCartModalOpen,
            };

            if (snippetActions[event.snippet.id]) {
                snippetActions[event.snippet.id]();
            }
        });
    });
};

const addEventListeners = (formSelector) => {
    document.querySelectorAll(formSelector).forEach((element) => {
        // Register callback only if form contains recaptcha
        if (element.querySelector('.g-recaptcha')) {
            renderedElements.delete(element.querySelector('.g-recaptcha').id);
            element.addEventListener('submit', formSubmitEventCallback);
        }
    });
};

const formSubmitEventCallback = (event) => {
    const form = event.currentTarget;
    if (Nette.validateForm(form, true)) {
        const submitBtn = form['nette-submittedBy'];
        event.preventDefault();

        // execute only reCAPTCHAs in submitted form
        grecaptcha.execute().then((token) => {
            // Put token into hidden field
            document.querySelectorAll('.g-recaptcha-response').forEach((element) => {
                element.textContent = token;
            });

            // Remove submit event listener, otherwise it will call itself infinitely
            form.removeEventListener('submit', formSubmitEventCallback);

            // Submit the form again
            if (submitBtn) {
                submitBtn.click();
            } else {
                const event = document.createEvent('HTMLEvents');
                event.initEvent('submit', true, false);
                form.dispatchEvent(event);
            }
        });
    }
};

initReCaptcha();
